import React from 'react'
import Helmet from 'react-helmet'

function SEO(props) {
	let MetaDescription = []
	let TitleTag = ''
	let ogImage = ''
	let ogUrl = ''

	if (props.data) {
		MetaDescription = props.data.MetaDescription
		TitleTag = props.data.TitleTag
	} else if (props.description || props.title) {
		MetaDescription = props.description
		TitleTag = props.title
	}

	if (props.ogImage) {
		ogImage = props.ogImage.imageFile.publicURL
	}

	if (props.ogUrl) {
		ogUrl = props.ogUrl.pathname
	}

	const lang = 'en'

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={TitleTag}
			titleTemplate={`%s`}
			meta={[
				{
					name: `description`,
					content: MetaDescription,
				},
				{
					property: `og:title`,
					content: TitleTag,
				},
				{
					property: `og:image`,
					content: `https://timacum.com${ogImage}`,
				},
				{
					property: `og:url`,
					content: `https://timacum.com${ogUrl}`,
				},
				{
					property: `og:description`,
					content: MetaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: 'timacum',
				},
				{
					name: `twitter:title`,
					content: TitleTag,
				},
				{
					name: `twitter:description`,
					content: MetaDescription,
				},
			]}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

export default SEO
