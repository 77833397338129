export const lightTheme = {
	body: '#FFF',
	menu: '#0C0C0C',
	logo: '#0C0C0C',
	text: '#0C0C0C',
	subText: '#8B8B8B',
	slider: '#FAFAFA',
	link: '#FF7800',
	blendLink: '#2a9bff',
	blendLinkReverse: '#FF7800',
	grey: '#F2F2F2',
	darkIcon: 1,
	lightIcon: 0,
	strokeWidth: '1px',
	menuLinkColor: 'transparent',
	swiperBullets: '#000',
	buttonBg: ' #2a2a2a',
	buttonColor: '#fff',
}

export const darkTheme = {
	body: '#0C0C0C',
	menu: '#FFF',
	logo: '#FFF',
	text: ' #EAE9E9',
	subText: '#8B8B8B',
	slider: '#0C0C0C',
	link: '#FF7800',
	blendLink: '#FF7800',
	blendLinkReverse: '#2a9bff',
	grey: '#1C1C20',
	darkIcon: 0,
	lightIcon: 1,
	strokeWidth: 0,
	menuLinkColor: '#0094ff',
	swiperBullets: '#fff',
	buttonBg: ' #fff',
	buttonColor: '#2a2a2a',
}
