import React, { useContext, useRef, useEffect } from 'react'
import gsap, { Elastic } from 'gsap'
import { useMediaPredicate } from 'react-media-hook'

import styled from 'styled-components'
import ThemeContext from '../../../context/ThemeContext'

const Toggle = React.memo(() => {
	const { theme, setTheme, setMTheme } = useContext(ThemeContext)
	const mobileScreen = useMediaPredicate('(max-width: 1024px)')

	useEffect(() => {
		if (mobileScreen) {
			setMTheme(true)
		} else {
			setMTheme(false)
		}
	}, [mobileScreen, setMTheme])

	const darkIconRef = useRef(null)
	const lightIconRef = useRef(null)

	const clickAnimation = () => {
		if (theme) {
			return gsap.fromTo(
				lightIconRef.current,
				{
					rotation: -90,
				},
				{
					rotation: 0,
					ease: Elastic.easeOut.config(1.2, 0.2),
				}
			)
		}

		gsap.fromTo(
			darkIconRef.current,

			{
				rotation: -180,
			},
			{
				rotation: 0,
				ease: Elastic.easeOut.config(1.2, 0.3),
				duration: 0.5,
			}
		)
	}

	// const setCursorStyle = props => {
	// 	document.getElementById('cursor').style.cssText = props
	// }

	return (
		<React.Fragment>
			<ToggleWrapp
				onClick={() => {
					setTheme(
						!theme,
						localStorage.setItem('light', JSON.stringify(!theme))
					)
					clickAnimation()
				}}
				// onMouseEnter={() => setCursorStyle('width:  10px; height: 10px;')}
				// onMouseLeave={() => setCursorStyle(false)}
			>
				<Icons>
					<div className="dark">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 80 80"
							ref={darkIconRef}
						>
							<g id="Layer_2" data-name="Layer 2">
								<g id="Layer_1-2" data-name="Layer 1">
									<circle fill="#0C0C0C" cx="40" cy="40" r="40" />
									<path
										fill="#fff"
										d="M61,40A23.559,23.559,0,0,1,19,54.663,23.558,23.558,0,0,0,35.09,16.557c.776-.076,1.559-.118,2.349-.118A23.558,23.558,0,0,1,61,40Z"
									/>
								</g>
							</g>
						</svg>
					</div>

					<div className="light">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 80 80"
							ref={lightIconRef}
						>
							<g id="Layer_2" data-name="Layer 2">
								<g id="Layer_1-2" data-name="Layer 1">
									<circle fill="#fff" cx="40" cy="40" r="40" />
									<rect
										fill="#242424"
										x="38.99"
										y="16.44"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
									/>
									<rect
										fill="#242424"
										x="57.165"
										y="34.615"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
										transform="translate(98.175 -18.175) rotate(90)"
									/>
									<rect
										fill="#242424"
										x="20.815"
										y="34.615"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
										transform="translate(61.825 18.175) rotate(90)"
									/>
									<rect
										fill="#242424"
										x="26.141"
										y="21.786"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
										transform="translate(65.562 27.186) rotate(135)"
									/>
									<rect
										fill="#242424"
										x="38.99"
										y="52.79"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
									/>
									<rect
										fill="#242424"
										x="51.825"
										y="47.455"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
										transform="translate(-21.889 52.836) rotate(-45)"
									/>
									<rect
										fill="#242424"
										x="51.822"
										y="21.762"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
										transform="translate(70.993 83.7) rotate(-135)"
									/>
									<rect
										fill="#242424"
										x="26.144"
										y="47.439"
										width="2.019"
										height="10.771"
										rx="1.007"
										ry="1.007"
										transform="translate(45.306 -3.729) rotate(45)"
									/>
									<path
										fill="#242424"
										d="M40,29.9A10.1,10.1,0,1,0,50.1,40,10.1,10.1,0,0,0,40,29.9Zm0,18.17A8.07,8.07,0,1,1,48.075,40,8.075,8.075,0,0,1,40,48.07Z"
									/>
								</g>
							</g>
						</svg>
					</div>
				</Icons>
			</ToggleWrapp>
			{!mobileScreen && <DarkMode styles={theme} />}
		</React.Fragment>
	)
})

const ToggleWrapp = styled.div`
	width: 50px;
	height: 50px;
	margin-right: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	position: absolute;
	top: 20px;
	right: 100px;
	z-index: 9;

	@media (max-width: 700px) {
		right: 50px;
	}

	@media (max-width: 525px) {
		top: 15px;
		right: 70px;
		width: 28px;
		height: 28px;
	}
`

const Icons = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	.dark,
	.light {
		position: absolute;
		top: 0;

		svg {
			width: 100%;
			padding: 10px;

			@media (max-width: 525px) {
				box-sizing: content-box;
			}
		}
	}

	.dark {
		opacity: ${({ theme }) => theme.darkIcon};
	}

	.light {
		opacity: ${({ theme }) => theme.lightIcon};
	}
`

const DarkMode = styled.div`
	visibility: ${(props) => (props.styles ? 'hidden' : 'visible')};
	clip-path: ${(props) =>
		props.styles ? 'circle(10px at calc(100% - 156px) 55px)' : 'circle(100%)'};
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	content: '';
	width: 100vw;
	height: 100vh;
	background: #0c0c0c;
	transition: all 0.5s ease-in-out;
	pointer-events: none;
	z-index: 0;

	@media (max-width: 525px) {
		clip-path: ${(props) =>
			props.styles ? 'circle(10px at calc(100% - 90px) 40px)' : 'circle(100%)'};
	}
`

export default Toggle
