import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import HoverContext from '../../../context/HoverContext'

import styled from 'styled-components'
import { ReactSVG } from 'react-svg'

const Logo = React.memo(({ publicURL, blendMode, seo, logoWhite }) => {
	const { setCursorStyle } = useContext(HoverContext)
	const [hovered, setHovered] = useState(false)

	const mouseEnter = (e) => {
		if (hovered === false) {
			setHovered(true)
			setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
		} else return
	}

	const mouseOut = (e) => {
		setHovered(false)
		setCursorStyle({
			height: false,
			width: false,
			transition: false,
		})
	}

	if (!publicURL) return null
	if (!seo) return null
	return (
		<LogoLink
			blendmode={!logoWhite ? blendMode : 'normal'}
			as={Link}
			to="/"
			aria-label="logo placeholder"
			className="bLogo"
			onMouseEnter={(e) => {
				mouseEnter(e)
			}}
			onMouseLeave={(e) => {
				mouseOut(e)
			}}
		>
			<LogoPlaceholder>
				<ReactSVG
					src={publicURL}
					beforeInjection={(svg) => {
						const desc = document.createElementNS(
							'http://www.w3.org/2000/svg',
							'desc'
						)
						desc.innerHTML = seo.alternativeText
						svg.prepend(desc)

						const title = document.createElementNS(
							'http://www.w3.org/2000/svg',
							'title'
						)
						title.innerHTML = seo.caption
						svg.prepend(title)
					}}
				/>
			</LogoPlaceholder>
		</LogoLink>
	)
})

const LogoLink = styled.a`
	z-index: 9;
	padding: 10px 0;
	mix-blend-mode: ${({ blendmode }) => {
		return blendmode
	}};
`

const LogoPlaceholder = styled.div`
	svg path {
		fill: white;
	}
`

export default Logo
