import React, { useContext } from 'react'
import styled from 'styled-components'
import Link from './ui/Link'
import HoverContext from '../context/HoverContext'

// Ui components
import Logo from './ui/Logo'

const slugify = require('slugify')

const Footer = React.memo(({ nextProject, data, location, customCTA }) => {
	const { setCursorStyle } = useContext(HoverContext)

	var slug
	if (nextProject) {
		slug = nextProject.Seo.Url
	}

	if (customCTA) {
		slug = slugify(customCTA, { lower: true })
	}

	// Logo url
	const { alternativeText, caption } = data.LogoWhite
	const { publicURL } = data.LogoWhite.imageFile

	return (
		<Wrapper>
			<TopWrap>
				{customCTA ? (
					<Nav
						as={Link}
						to={'/' + slug + '/'}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
					>
						<Label>{'NEXT'}</Label>
						<Title>{customCTA}</Title>
					</Nav>
				) : (
					<Nav
						as={Link}
						to={nextProject ? '/project/' + slug : '/get-in-touch/'}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
					>
						<Label>{nextProject ? 'NEXT PROJECT' : 'GET IN TOUCH'}</Label>
						<Title>{nextProject ? nextProject.Client : 'Contact us'}</Title>
					</Nav>
				)}

				<LinksWrap>
					<div className="row">
						{location &&
							location.pathname !== '/' &&
							location.pathname !== '/who-we-are/' &&
							location.pathname !== '/our-work/' &&
							location.pathname !== '/privacy-policy/' &&
							location.pathname !== '/blog/' &&
							location.pathname.search('/post') !== 0 && (
								<CLink
									as={Link}
									to="/get-in-touch/"
									onMouseEnter={() => {
										setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
									}}
									onMouseLeave={() => {
										setCursorStyle({
											height: false,
											width: false,
											transition: false,
										})
									}}
								>
									Get in touch
								</CLink>
							)}

						<Link
							to="/our-work/"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Our work
						</Link>
						<Link
							to="/who-we-are/"
							onMouseEnter={() => {
								setCursorStyle({
									height: 0,
									width: 0,
									transition: '.1.2s',
								})
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Who we are
						</Link>
						<Link
							to="/blog/"
							onMouseEnter={() => {
								setCursorStyle({
									height: 0,
									width: 0,
									transition: '.1.2s',
								})
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Blog
						</Link>
					</div>
					<div className="row">
						<Link
							to="/services/"
							onMouseEnter={() => {
								setCursorStyle({
									height: 0,
									width: 0,
									transition: '.1.2s',
								})
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Services
						</Link>

						<Link
							to="/blockchain/"
							onMouseEnter={() => {
								setCursorStyle({
									height: 0,
									width: 0,
									transition: '.1.2s',
								})
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Blockchain
						</Link>

						<Link
							to="/white-label/"
							onMouseEnter={() => {
								setCursorStyle({
									height: 0,
									width: 0,
									transition: '.1.2s',
								})
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							White-label
						</Link>
					</div>
					<div className="row">
						<p>{data.Address}</p>
						<p>{data.Address2}</p>
						<p>{data.Zip}</p>
					</div>
				</LinksWrap>
			</TopWrap>
			<BottomWrapp>
				<Logo publicURL={publicURL} seo={{ alternativeText, caption }} />
				<RightColumn>
					{location && location.pathname !== '/privacy-policy/' && (
						<Privacy
							as={Link}
							to="/privacy-policy/"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Privacy & Cookie policy
						</Privacy>
					)}
					<IconWrap
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({ height: false, width: false, transition: false })
						}}
						style={{ cursor: 'pointer' }}
					>
						{data.SocialIcon.map((icon, index) => {
							return (
								<SocialIcons
									key={'icon' + index}
									href={icon.Link}
									target="_blank"
								>
									<img
										loading="lazy"
										src={icon.Icon.imageFile.publicURL}
										alt={icon.Icon.alternativeText}
										title={icon.Icon.caption}
									/>
								</SocialIcons>
							)
						})}
					</IconWrap>
				</RightColumn>
			</BottomWrapp>
		</Wrapper>
	)
})

const Wrapper = styled.footer`
	font-size: 1.6rem;
	background: #0c0c0c;
	color: white;
	padding: 192px 60px 50px 60px;
	position: relative;
	z-index: 1;

	@media (max-width: 1024px) {
		padding: 64px 60px 0 60px;
	}

	@media (max-width: 525px) {
		padding: 64px 20px 0 20px;
	}
`
const TopWrap = styled.div`
	display: flex;
	align-items: center;
	max-width: 1408px;
	margin: 0 auto;
	align-items: flex-start;
	margin-bottom: 192px;

	@media (max-width: 1024px) {
		margin-bottom: 64px;
	}

	@media (max-width: 840px) {
		flex-direction: column;
	}

	@media (max-width: 525px) {
		margin-bottom: 0;
		flex-direction: column;
		align-items: unset;
	}
`
const Nav = styled.div`
	padding: 0;
	display: flex;
	flex-direction: column;
	transition: all 0.3s;
	flex: 1;

	p {
		transition: all 0.3s ease-in-out;

		&::before {
			transition: all 0.3s ease-in-out;
		}

		&:nth-of-type(2) {
			-webkit-text-stroke-width: 1px;
			-webkit-text-stroke-color: #ff7800;
		}
	}

	@media (min-width: 1025px) {
		&:hover {
			p:nth-of-type(1) {
				&::before {
					left: 45px;
					width: 45px;
				}
			}

			p:nth-of-type(2) {
				padding-left: 45px;
				color: transparent;
			}
		}
	}

	@media (max-width: 1024px) {
		padding: 0;
		padding-bottom: 10px;
		margin-bottom: 60px;
	}

	@media (max-width: 525px) {
		margin-bottom: 60px;
	}
`

const LinksWrap = styled.div`
	display: flex;

	.row {
		display: flex;
		flex-direction: column;
		margin-right: 95px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	p {
		font-size: 18px;
		line-height: 27px;
		opacity: 0.6;
		margin: 0;
		font-family: 'Poppins Regular';
	}

	a {
		text-transform: capitalize;
		color: white;
		transition: opacity 0.25s;
		margin-bottom: 1rem;

		&:hover {
			opacity: 0.5;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@media (max-width: 1024px) {
		.row {
			margin-right: 40px;
		}

		a,
		p {
			font-size: 14px;
		}

		a {
			margin-bottom: 12px;
		}
	}

	@media (max-width: 768px) {
		.row {
			margin-right: 95px;
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 0;
		flex-direction: column;

		.row {
			margin-bottom: 48px;
			margin-right: 0;

			&:last-of-type {
				margin-bottom: 70px;
			}
		}

		a {
			&:first-of-type {
				margin-top: 0;
				margin-bottom: 16px;
			}

			font-size: 14px;
			line-height: 12px;
			margin-bottom: 16px;
		}
	}
`

const Label = styled.p`
	color: #fff;
	align-self: flex-start;
	text-transform: uppercase;
	font: 1rem/1.3125rem 'RobotoMono Bold';
	position: relative;
	padding-left: 6.25em;
	transition: all 0.3s;
	margin-top: 0;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 1px;
		width: 90px;
		background-color: #fff;
	}

	@media (max-width: 1024px) {
		font-size: 0.6875rem;
		line-height: 0.9375rem;

		&:before {
			width: 50px;
		}
	}
`

const Title = styled.p`
	font: 4.0625rem/5.625rem 'Mont Heavy';
	color: #ff7800;
	text-transform: capitalize;
	margin: 0;

	@media (max-width: 1024px) {
		font-size: 2rem;
		line-height: 2.75rem;
	}
`

const BottomWrapp = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 20px;
	max-width: 1408px;
	margin: 0 auto;

	p {
		margin-right: 100px;
	}

	.bLogo {
		opacity: 0.6;
	}

	@media (max-width: 1024px) {
		padding-bottom: 25px;
	}

	@media (max-width: 768px) {
		.bLogo {
			display: none;
		}
	}

	@media (max-width: 525px) {
		justify-content: space-between;

		p {
			margin-right: 0;
		}
	}
`
const RightColumn = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 768px) {
		justify-content: space-between;
	}

	@media (max-width: 525px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
`

const Privacy = styled.div`
	font: 0.75rem/1.125rem 'Poppins Regular';
	color: #c2c2c2;
	text-transform: capitalize;
	transition: opacity 0.25s;
	margin-right: 40px;

	&:hover {
		opacity: 0.5;
	}

	@media (max-width: 525px) {
		margin-top: 2rem;
		order: 1;
	}
`

const IconWrap = styled.div`
	display: flex;
`

const SocialIcons = styled.a`
	display: flex;
	margin-right: 40px;
	transition: opacity 0.25s;

	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		opacity: 0.5;
	}

	@media (max-width: 768px) {
		margin-right: 15px;
	}

	@media (max-width: 525px) {
		img {
			width: 90%;
		}
	}
`
const CLink = styled.a`
	background-color: white;
	color: black !important;
	border-radius: 3px;
	padding: 10px 16px;
	display: inline-flex;
	width: fit-content;
`

export default Footer
