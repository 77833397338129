import React from 'react'
import styled from 'styled-components'

const Paragraph = React.memo((props) => {
	const { children } = props

	return (
		<Row {...props}>
			<Wrapper children={children} />
		</Row>
	)
})

const Row = styled.div.attrs({
	className: 'content-item text',
})`
	overflow: hidden;
	font: 1.125rem / 2rem 'Poppins Regular';
	letter-spacing: 0.58px;
	color: white;
	mix-blend-mode: difference;

	@media (max-width: 1024px) {
		font-size: 0.875rem;
		line-height: 1.625rem;
	}
`

const Wrapper = styled.p`
	font: inherit;
	margin: 0;
	position: relative;
	z-index: 1;

	@media (max-width: 1024px) {
		font-size: 0.875rem;
		line-height: 1.625rem;
	}
`

export default Paragraph
