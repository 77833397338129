import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Global settings
import '../fonts/fonts.css'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from '../components/ui/Theme'
import ThemeContext from '../context/ThemeContext'

import Header from './header'
import Footer from './footer'
import Cursor from './ui/Cursor'
import GDPR from './ui/GDPR'

const Layout = React.memo(
	({
		children,
		noFooter,
		location,
		nextProject,
		logoWhite,
		customCTA,
		noExist,
	}) => {
		const data = useStaticQuery(graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
				timacum {
					themeSetting {
						LogoBlack {
							url
							alternativeText
							caption
							imageFile {
								publicURL
							}
						}
						LogoWhite {
							url
							alternativeText
							caption
							imageFile {
								publicURL
							}
						}
						Email
						Address
						Address2
						Zip
						SocialIcon {
							Link
							Icon {
								url
								alternativeText
								caption
								imageFile {
									publicURL
								}
							}
						}
					}
				}
			}
		`)

		const { open, theme, mTheme } = useContext(ThemeContext)

		return (
			<ThemeProvider theme={theme === true ? lightTheme : darkTheme}>
				<GlobalStyle open={open} />
				<Cursor />
				<LCont className="content" mTheme={mTheme} isLight={theme}>
					<Header
						siteTitle={data.site.siteMetadata.title}
						// className={location ? location : ''}
						data={data.timacum.themeSetting}
						location={location}
						logoWhite={logoWhite}
						noExist={noExist} // hide toogle dark/light for 404 pages
					/>
					<main>{children}</main>
					{!noFooter && (
						<Footer
							customCTA={customCTA}
							nextProject={nextProject}
							data={data.timacum.themeSetting}
							location={location}
						/>
					)}
				</LCont>
				<GDPR />
			</ThemeProvider>
		)
	}
)

const LCont = styled.div`
	main {
		background-color: ${({ mTheme, isLight }) => {
			if (mTheme) {
				if (!isLight) {
					return 'black'
				} else {
					return 'white'
				}
			}
			return 'white'
		}};
		transition: background-color ease 0.25s;
	}
`

const GlobalStyle = createGlobalStyle`

@keyframes rotate {
	0% {
		transform: rotate(0deg)
	}
	100% {
		transform: rotate(360deg);
	}
}

	html,body {
		margin: 0;
		padding: 0;
		border: 0;
		vertical-align: baseline;
		cursor: none;
		position: ${({ open }) => {
			if (open) {
				return 'fixed'
			}
		}};
	}

	body {
		font-size: 62.5%;
		width: 100%;
		overflow-y: scroll;
	}

	html {
		--scrollbarBG: #eaeaea;
		--thumbBG: #9e9d9d;
	}

	// body::-webkit-scrollbar {
	// 	width: 11px;
	// }

	// body {
	// 	scrollbar-width: thin;
	// 	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
	// }
	
	// body::-webkit-scrollbar-track {
	// 	background: var(--scrollbarBG);
	// }
	
	// body::-webkit-scrollbar-thumb {
	// 	background-color: var(--thumbBG) ;
	// 	border-radius: 6px;
	// 	border: 3px solid var(--scrollbarBG);
	// }

	ol, ul {
		list-style: none;
	}

	*, *:before, *:after {
		-webkit-box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
	}

	a {
		text-decoration: none;
		text-transform: uppercase;
		cursor: none;
		font-size: 1.125rem;
		line-height: 1.6875rem;
		font-family: 'Poppins Regular';

		&:hover {
			cursor: pointer;
		}

	}

	textarea, input{
		cursor: none;
	}

	button {
		padding: 0;
		border: none;
		outline: none;
		background: none;
		cursor: none;

		&:hover {
			cursor: pointer;
		}
	}

	span {
		color: ${({ theme }) => theme.text};
		font: 15px/23px 'Poppins Regular';
	}

	#gatsby-announcer {
		display:none;
	}
`

export default Layout
