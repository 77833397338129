import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Link from '../Link'

const GDPR = React.memo((props) => {
	let windowGlobal = typeof window !== 'undefined' && window
	const [gdpr, setGdpr] = useState(true)

	useEffect(() => {
		if (windowGlobal) {
			setGdpr(JSON.parse(windowGlobal.localStorage.getItem('GDPR')))
		}
	}, [windowGlobal])

	return (
		<React.Fragment>
			{gdpr !== true && (
				<Wrapper>
					<p>This website uses cookies to improve your experience.</p>
					<Link to="/privacy-policy/">Privacy & Cookie policy</Link>
					<button
						type="button"
						onClick={(e) => {
							e.preventDefault()
							if (windowGlobal) {
								windowGlobal.localStorage.setItem('GDPR', true)
								setGdpr(true)
							}
						}}
						aria-label="accept"
					>
						ACCEPT
					</button>
				</Wrapper>
			)}
		</React.Fragment>
	)
})

const Wrapper = styled.section`
	position: fixed;
	width: 1408px;
	max-width: 1408px;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	z-index: 9998;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	text-align: center;
	font-size: 12px;
	line-height: 32px;
	font-family: 'Poppins Regular';
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;

	p {
		margin-right: 8px;
	}

	a {
		font-size: inherit;
		line-height: inherit;
		color: white;
		text-transform: capitalize;
		text-decoration: underline;
		margin-right: 64px;
		transition: opacity 0.25s;

		&:hover {
			opacity: 0.7;
		}
	}

	button {
		color: white;
		border: 1px solid white;
		border-radius: 3px;
		padding: 10px 40px;
		transition: opacity 0.25s;

		&:hover {
			opacity: 0.7;
		}

		&:hover,
		&:focus {
			outline: none;
		}
	}

	@media (min-width: 1800px) {
		top: 0;
		bottom: unset;
	}

	@media (max-width: 700px) {
		flex-direction: column;

		p,
		a {
			margin: 0;
		}

		a {
			margin-bottom: 1rem;
		}
	}

	@media (max-width: 525px) {
		font-size: 10px;
		line-height: 32px;

		background-color: rgba(0, 0, 0, 1);
		padding: 15px 0;

		button {
			padding: 10px 40px;
			font-size: 10px;
		}
	}
`

export default GDPR
