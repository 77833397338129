import React from 'react'
import styled from 'styled-components'

// Ui components
import Menu from './menu'
import Toggle from './ui/Toggle'
import Logo from './ui/Logo'

const Header = React.memo(
	({ className, location, data, logoWhite, noExist }) => {
		const { alternativeText, caption } = data.LogoBlack
		const { publicURL } = data.LogoBlack.imageFile
		// Logo render if single project and menu active class
		let blendMode = 'difference'
		let singleProject = false
		let singlePost = false
		if (location.pathname.indexOf('/project/') > -1) {
			blendMode = 'none'
			singleProject = true
		}

		if (location.pathname.indexOf('/post/') > -1) {
			blendMode = 'none'
			singlePost = true
		}

		return (
			<Wrapper className={className ? className : ''}>
				<Logo
					publicURL={publicURL}
					blendMode={blendMode}
					seo={{ alternativeText, caption }}
					logoWhite={logoWhite}
				/>
				<LeftWrapper>
					{(() => {
						if (location.pathname === '/get-in-touch/') {
							return null
						} else if (noExist === true) {
							return null
						} else {
							return <Toggle />
						}
					})()}
					<Menu
						contact={className ? className : ''}
						blendMode={blendMode}
						data={data}
						singleProject={singleProject}
						singlePost={singlePost}
						logoWhite={logoWhite}
					/>
				</LeftWrapper>
			</Wrapper>
		)
	}
)

const Wrapper = styled.header`
	position: absolute;
	padding: 2em 5em;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	&.contact a {
		svg path {
			fill: white;
		}
	}

	@media (max-width: 700px) {
		padding: 1.6em 1em 1.6em 2em;
	}
`

const LeftWrapper = styled.div`
	display: flex;
`

export default Header
