import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Link from '../components/ui/Link'
import ThemeContext from '../context/ThemeContext'
import HoverContext from '../context/HoverContext'
import Paragraph from './ui/Paragraph'

const Menu = React.memo(
	({ blendMode, data, singleProject, singlePost, logoWhite }) => {
		const { open, setOpen } = useContext(ThemeContext)
		const { setCursorStyle, setCursorPos } = useContext(HoverContext)
		const [menuWidth, setMenuWidth] = useState(false)

		const isAppleDevice = () => {
			return (
				navigator.userAgent.toLowerCase().indexOf('ipad') > -1 ||
				navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
				navigator.userAgent.toLowerCase().indexOf('ipod') > -1
			)
		}

		return (
			<React.Fragment>
				<Wrapper
					open={open}
					className={open && 'open'}
					blendmode={!logoWhite ? blendMode : 'normal'}
					onClick={() => {
						setOpen(!open)
						setMenuWidth(false)
					}}
					onMouseEnter={(e) => {
						if (!isAppleDevice()) {
							// e.preventDefault()
							const { width, height } = e.target.getBoundingClientRect()
							const { offsetLeft, offsetTop } = e.target
							var pageOffset
							if (typeof window !== `undefined`) {
								pageOffset = window.pageYOffset
							}
							setCursorStyle({
								height: 60,
								width: 60,
								transition: 'all .5s',
							})
							setCursorPos({
								x: offsetLeft + width / 2,
								y: offsetTop - pageOffset + height / 2,
							})
							setMenuWidth('100%')
						}
					}}
					onMouseLeave={(e) => {
						if (!isAppleDevice()) {
							// e.preventDefault()
							setCursorStyle({ height: false, width: false, transition: false })
							setCursorPos({
								x: false,
								y: false,
							})
							setMenuWidth(false)
						}
					}}
				>
					<Line
						style={{
							marginLeft: 'auto',
							background: 'white',
							width: menuWidth && menuWidth,
						}}
						open={open}
					></Line>
					<Line
						style={{
							margin: '4px auto 4px 0',
							width: '32px',
							background: 'white',
						}}
						open={open}
					></Line>
					<Line
						style={{
							background: 'white',
							width: menuWidth && menuWidth,
						}}
						open={open}
					></Line>
				</Wrapper>
				<Nav
					style={{
						top: open && 0,
						transitionDelay: !open && '.4s',
						pointerEvents: !open && 'none',
					}}
				>
					<div className="item">
						<MenuLinks
							to={'/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Home
						</MenuLinks>
					</div>
					<div className="item">
						<MenuLinks
							to={'/our-work/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							className={singleProject && 'active'}
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Our work
						</MenuLinks>
					</div>
					<div className="item">
						<MenuLinks
							to={'/services/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Services
						</MenuLinks>
					</div>
					<div className="item">
						<MenuLinks
							to={'/blockchain/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Blockchain
						</MenuLinks>
					</div>
					<div className="item">
						<MenuLinks
							to={'/white-label/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							White label
						</MenuLinks>
					</div>
					<div className="item">
						<MenuLinks
							to={'/blog/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							className={singlePost && 'active'}
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Blog
						</MenuLinks>
					</div>
					<div className="item">
						<MenuLinks
							to={'/who-we-are/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Who we are
						</MenuLinks>
					</div>

					<div className="item">
						<MenuLinks
							to={'/get-in-touch/'}
							onClick={() => setOpen(!open)}
							style={{ bottom: open && 0 }}
							open={open}
							activeClassName="active"
							onMouseEnter={() => {
								setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
							}}
							onMouseLeave={() => {
								setCursorStyle({
									height: false,
									width: false,
									transition: false,
								})
							}}
						>
							Get in touch
						</MenuLinks>
					</div>

					<Address
						style={{
							top: open && '50%',
							transitionDelay: !open && '.4s',
							pointerEvents: !open && 'none',
						}}
					>
						<HPara as={Paragraph} open={open}>
							{data.Address}
						</HPara>
						<HPara as={Paragraph} open={open}>
							{data.Address2}
						</HPara>
						<HPara as={Paragraph} open={open}>
							{data.Zip}
						</HPara>
						<EmailWrap open={open}>
							<a className="email-link" href={`mailto:${data.Email}`}>
								{data.Email}
							</a>
						</EmailWrap>
					</Address>

					<IconWrap
						style={{
							bottom: open && '2vh',
							transitionDelay: !open && '.4s',
							cursor: 'pointer',
						}}
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({
								height: false,
								width: false,
								transition: false,
							})
						}}
					>
						{data.SocialIcon.map((icon, index) => {
							return (
								<SocialIcons
									key={'icons' + index}
									href={icon.Link}
									target="_blank"
								>
									<img
										className={open ? 'open' : 'closed'}
										loading="lazy"
										src={icon.Icon.imageFile.publicURL}
										alt=""
									/>
								</SocialIcons>
							)
						})}
					</IconWrap>
				</Nav>
			</React.Fragment>
		)
	}
)

const Wrapper = styled.button`
	display: flex;
	flex-direction: column;
	z-index: 1003;
	padding: 10px;
	width: 52px;
	height: 52px;
	justify-content: center;
	align-items: center;
	mix-blend-mode: ${({ blendmode }) => {
		return blendmode
	}};

	span {
		transition: width 0.25s ease-in, transform 0.25s ease-in,
			background 0.6s ease-in;
	}

	&.open {
		mix-blend-mode: difference;
	}

	@media (max-width: 525px) {
		transform: scale(0.8);
		padding-bottom: 15px;
	}
`

const Line = styled.span`
	height: 4px;
	width: 20px;

	&:first-of-type {
		transform: ${(props) => {
			if (props.open) {
				return 'translateY(7px) rotate(45deg)'
			}
		}};

		width: ${(props) => {
			if (props.open) {
				return '100%'
			}
		}};
	}

	&:nth-of-type(2) {
		width: ${(props) => {
			if (props.open) {
				return '0 !important'
			}
		}};
	}

	&:nth-of-type(3) {
		margin-right: auto;
		transform: ${(props) => {
			if (props.open) {
				return 'translateX(0) translateY(-9px) rotate(-45deg)'
			}
		}};

		width: ${(props) => {
			if (props.open) {
				return '100%'
			}
		}};
	}
`

const Nav = styled.div`
	position: fixed;
	transform: translate3d(0, 0, 0);
	top: -100vh;
	left: 0;
	background-color: ${({ theme }) => theme.menu};
	display: flex;
	width: 100vw;
	height: 100vh;
	z-index: 1002;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 25em;
	transition: all 0.4s ease-in-out;
	margin: 0;

	div {
		overflow: hidden;
		display: flex;

		a {
			bottom: -88px;
			min-width: 50%;
			padding-bottom: 10px;
		}
	}

	@media (max-width: 1366px) {
		padding-left: 8em;
	}

	@media (max-width: 1024px) {
		padding-left: 6em;
	}

	@media (max-width: 768px) {
		padding-left: 4.8em;

		div a {
			padding-bottom: 1rem;
		}
	}

	@media (max-width: 525px) {
		padding-top: 95px;
		padding-bottom: 60px;
		display: block;

		div {
			a {
				padding-bottom: 3px;
			}

			/* &.item:first-of-type {
				margin-top: 130px;
			} */
		}

		padding-left: 2em;
		height: 100vh;
		overflow: scroll;
	}

	@media (max-width: 525px) and (min-height: 768px) {
		padding-top: 0;

		div {
			&.item:first-of-type {
				margin-top: 70px;
			}
		}
	}
`

const MenuLinks = styled(Link)`
	color: #fff;
	mix-blend-mode: difference;
	text-transform: capitalize;
	font: 58px/72px 'Mont Heavy';
	position: relative;
	-webkit-text-stroke-width: ${({ theme }) => theme.strokeWidth};
	-webkit-text-stroke-color: #fff;
	transition: ${(props) => {
		if (props.open) {
			return 'padding-left 0.25s linear 0s, color 0.25s linear 0s, -webkit-text-stroke-color 0.25s linear 0s, bottom 0.45s linear 0.4s'
		}
		return 'padding-left 0.25s linear 0s, color 0.25s linear 0s, -webkit-text-stroke-color 0.25s linear 0s, bottom 0.45s linear 0s'
	}};

	@media (min-width: 1025px) {
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 1px;
			width: 0;
			background-color: white;
			transition: all 0.25s;
		}

		&:hover,
		&.active {
			-webkit-text-stroke-color: #ff7800;
			padding-left: 45px;
			color: ${({ theme }) => theme.menuLinkColor};

			&::before {
				width: 30px;
			}
		}

		&.active {
			-webkit-text-stroke-color: transparent;
			color: ${({ theme }) => theme.blendLinkReverse};
		}

		/* &:hover {
		-webkit-text-stroke-color: #ff7800;
		color: transparent;
	} */
	}

	@media (max-width: 1024px) {
		font-size: 3.5rem;
		line-height: 6rem;
		-webkit-text-stroke-color: transparent;
		-webkit-text-stroke-width: 0;

		&.active,
		&:focus,
		&:hover {
			-webkit-text-stroke-color: transparent;
			-webkit-text-stroke-width: 1px;
			padding-left: 0;
			color: #fff;
			opacity: 0.3;

			&::before {
				display: none;
			}
		}

		/* &:hover {
			-webkit-text-stroke-color: unset;
			padding-left: unset;
			color: #fff;

			&::before {
				display: none;
			}
		} */
	}

	@media (max-width: 768px) {
		font-size: 2rem;
		line-height: 2.75rem;
	}

	@media (max-width: 525px) {
		font-size: 2.5rem;
		line-height: 3.4375rem;
	}

	@media (max-width: 360px) {
		font-size: 2rem;
		line-height: 3rem;
	}
`
const Address = styled.section`
	color: white;
	position: fixed;
	top: -100%;
	transform: translateY(-50%);
	right: 25em;
	z-index: 9999;
	mix-blend-mode: difference;

	& > div {
		margin-bottom: 1rem;
		overflow: hidden;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@media (max-width: 1366px) {
		right: 8em;
	}

	@media (max-width: 1024px) {
		right: 6em;
	}

	@media (max-width: 768px) {
		right: 4.8em;
	}

	@media (max-width: 525px) {
		right: unset;
		top: unset !important;
		left: unset;
		bottom: unset;
		position: relative;
		transform: unset;
		margin-top: 64px;
		margin-bottom: 55px;
	}
`
const HPara = styled.p`
	font-size: 1.125rem;
	line-height: 1.6875rem;

	p {
		transition: bottom 1s;
		bottom: ${({ open }) => {
			if (open) {
				return '0'
			} else {
				return '-88px;'
			}
		}};
	}

	@media (max-width: 525px) {
		margin-bottom: 8px !important;

		p {
			margin-bottom: 0;
		}
	}
`

const EmailWrap = styled.div`
	font-size: 1.125rem;
	line-height: 1.6875rem;
	overflow: hidden;

	a.email-link {
		position: relative;
		transition: opacity 0.25s, bottom 1s;
		color: white;
		text-transform: lowercase;
		bottom: ${({ open }) => {
			if (open) {
				return '0'
			} else {
				return '-88px;'
			}
		}};

		&:hover {
			opacity: 0.5;
		}
	}

	@media (max-width: 1024px) {
		a {
			font-size: 0.875rem;
			line-height: 1.625rem;
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 8px !important;

		a {
			margin-bottom: 0;
		}
	}
`
const IconWrap = styled.section`
	display: flex;
	position: fixed;
	bottom: -20%;
	z-index: 9999;
	right: 25em;
	mix-blend-mode: difference;
	transition: all 0.8s ease-in-out;

	@media (max-width: 1366px) {
		right: 8em;
	}

	@media (max-width: 1024px) {
		right: 6em;
	}

	@media (max-width: 768px) {
		right: 4.8em;
	}

	@media (max-width: 525px) {
		left: unset;
		position: relative;
		bottom: unset;
		right: unset;
	}
`

const SocialIcons = styled.a`
	display: flex;
	margin-right: 40px;
	transition: opacity 0.25s;
	overflow: hidden;

	img {
		transition: bottom 0.8s;
		position: relative;
		bottom: -88px;

		&.open {
			bottom: 0;
		}
	}

	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		opacity: 0.5;
	}

	@media (max-width: 768px) {
		margin-right: 15px;
	}

	@media (max-width: 525px) {
		img {
			width: 90%;
		}
	}
`

export default Menu
