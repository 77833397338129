import React, { useContext } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from '../../../context/ThemeContext'
import { useMediaPredicate } from 'react-media-hook'

const Link = React.memo((props) => {
	const { children } = props
	const { theme } = useContext(ThemeContext)
	const mobileScreen = useMediaPredicate('(max-width: 1024px)')

	return (
		<AniLink
			{...props}
			cover={!mobileScreen}
			to={props.to}
			bg={theme ? '#0C0C0C' : '#FFF'}
			direction="left"
		>
			{children}
		</AniLink>
	)
})

export default Link
