import React, { useState, useRef, useEffect, useContext } from 'react'
import styled from 'styled-components'
import HoverContext from '../../../context/HoverContext'

const Cursor = React.memo(() => {
	const [cursor, setCursor] = useState({ x: null, y: null })
	const { cursorStyle, cursorPos } = useContext(HoverContext)

	useEffect(() => {
		if (typeof window !== `undefined`) {
			const {
				innerWidth: width,
				// innerHeight: height
			} = window

			window.onmousemove = function (e) {
				measuredRef(e, cursorPos, width)
			}

			const measuredRef = (e, cursorPos, pageW) => {
				if (cursorPos.x) {
					setCursor({ x: cursorPos.x, y: cursorPos.y })
				} else {
					setCursor({ x: e.clientX, y: e.clientY })
					if (e.clientX > pageW - 18) {
						setCursor({ x: e.clientX + 14, y: e.clientY })
					}
				}
			}
		}
	}, [cursorPos])

	const cursorRef = useRef(null)

	return (
		<CursorWrapper
			ref={cursorRef}
			style={{
				top: cursor.y,
				left: cursor.x,
				width: cursorStyle.width,
				height: cursorStyle.height,
				transition: cursorStyle.transition,
				border: cursorStyle.border,
				backgroundColor: cursorStyle.backgroundColor,
			}}
		>
			<div>
				<span style={{ width: cursorStyle ? 'auto' : 0 }}>
					{cursorStyle.text}
				</span>
			</div>
		</CursorWrapper>
	)
})

const CursorWrapper = styled.div.attrs({
	id: 'cursor',
})`
	position: fixed;
	display: block;
	z-index: 9999;
	width: 20px;
	height: 20px;
	mix-blend-mode: difference;
	pointer-events: none;
	transform: translate(-50%, -50%);
	top: -100px;
	left: -100px;
	background-color: white;
	border-radius: 50%;
	transition: width 0.5s ease 0s, height 0.5s ease 0s, border 0.5s ease 0s,
		background-color 0.25s ease 0s;
	overflow: hidden;

	div {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		span {
			color: white;
			mix-blend-mode: difference;
			font-size: 10px;
			text-transform: uppercase;
			letter-spacing: 2px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	@media (max-width: 1024px) {
		display: none;
	}
`
export default Cursor
